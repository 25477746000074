<template>
  <div>
    <v-observer tag="form" class="animated fadeIn form-rounded-inputs" :ref=" ref " @submit.prevent="fnValidateData()">
      <div class="row">
        <div class="col-12" v-if="!disabledFields.addressName">

          <div class="form-group">
            <label for="" class=" w-100 " v-text=" $t('dashboard.store.addressesModal.storeName') ">
            </label>
            <v-validation rules="min:3|max:30" v-slot="{ errors }"
              :name=" $t('dashboard.store.addressesModal.storeName') ">
              <input type="text" v-model="newAddress.address_name" :disabled="disabledFields.addressName"
                :required="!disabledFields.addressName" :placeholder="$t('dashboard.store.addressesModal.storeName') "
                class="form-control" :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>
        </div>

        <div class="col-6 col-sm-6">
          <div class="form-group">
            <label for="" v-text=" $t('general.form.firstName') "> </label>
            <v-validation rules="min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.firstName') ">
              <input type="text" v-model="newAddress.first_name" placeholder="Robert" class="form-control"
                :disabled="disabledFields.userName" :required="!disabledFields.userName"
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>

            </v-validation>
          </div>
        </div>

        <div class="col-6 col-sm-6">
          <div class="form-group">
            <label for="" v-text=" $t('general.form.lastName') "> </label>
            <v-validation rules="min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.lastName') ">
              <input type="text" v-model="newAddress.last_name" class="form-control" placeholder="Martin"
                :disabled="disabledFields.userLastName" :required="!disabledFields.userLastName"
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="" v-text=" $t('general.form.address1') "> </label>
            <v-validation rules="required|min:3|max:50" v-slot="{ errors }" :name=" $t('general.form.address1') ">
              <input type="text" v-model="newAddress.address1" class="form-control" placeholder="Street name "
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="" v-text=" $t('general.form.address2') "> </label>
            <v-validation rules="required|max:50" v-slot="{ errors }" :name=" $t('general.form.address2') ">
              <input type="text" v-model="newAddress.address2" class="form-control" placeholder="Street number"
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>
        </div>

        <div class="col-12 col-sm-6">

          <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.country') ">

            <div class="form-group">
              <label for="" v-text="$t('general.form.country')"></label>
              <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                <select class="custom-select" @change="fnGetStates( newAddress.country.code , true )"
                  :class=" { 'is-invalid ':errors.length > 0 } " v-model="newAddress.country.code">
                  <option :value="null" selected v-text=" $t('general.form.select') ">
                  </option>
                  <option :value="country.code" v-for=" country in countries  " :key="country.name"
                    v-text="country.name">
                  </option>
                </select>
                <div class="input-group-append">
                  <label class="input-group-text"> <i class="fa "
                      :class=" loading.countries ? 'fa-spinner fa-spin':'fa-flag' "></i> </label>
                </div>
              </div>
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </v-validation>

        </div>
        <div class="col-12 col-sm-6">

          <div v-show=" statlesList.length == 0 ">
            <v-validation :rules=" statlesList.length == 0 ? 'required' : ''" v-slot="{ errors }"
              :name=" $t('general.form.state') ">
              <div class="form-group">
                <label for="">
                  <span v-text=" $t('general.form.state') "></span>
                </label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="newAddress.state.name" placeholder="Nuevo Leon"
                    :class=" { 'is-invalid':(errors.length > 0 || newAddress.state.name == null ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa" :class=" loading.states ? 'fa-spinner fa-spin':'fa-map-marker-alt' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>
          <div v-show=" statlesList.length > 0 ">
            <v-validation :rules=" statlesList.length > 0 ? 'required' : ''" v-slot="{ errors }"
              :name="  $t('general.form.state') ">
              <div class="form-group">
                <label for="" v-text="$t('general.form.state')"></label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select" @change="fnGetProvinces(newAddress.state.code,true)"
                    :class=" { 'is-invalid ':errors.length > 0 } " v-model="newAddress.state.code">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="std['code_2_digits']" v-for=" (std,index) in  statlesList  " :key="index"
                      v-text="std.name">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text"> <i class="fa "
                        :class=" loading.states ? 'fa-spinner fa-spin':'fa-map-marker-alt' "></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>
        </div>

        <div class="col-12 col-md-4 ">
          <div class="form-group" v-show=" provincesList.length == 0 ">
            <label for="" v-text="$t('general.form.city')"> </label>
            <v-validation :rules=" provincesList.length == 0 ? 'required|min:3|max:30' : ''" v-slot="{ errors }"
              :name=" $t('general.form.city') ">
              <input type="text" v-model="newAddress.city" class="form-control" placeholder="Monterrey"
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>

          <div class="form-group" v-show=" provincesList.length > 0 ">
            <label for="" v-text=" $t('general.form.province')  "> </label>
            <v-validation :rules=" provincesList.length > 0 ? 'required' : ''" v-slot="{ errors }"
              :name=" $t('general.form.province') ">
              <select class="form-control" v-model="newAddress.city" :class=" { 'is-invalid':errors.length > 0 } ">
                <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                <option class="text-capitalize" :value="prov.code" v-for=" (prov,index) in provincesList  "
                  :key="index + 'prov'" v-text="prov.name">
                </option>
              </select>
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>

        </div>

        <div class="col-6 col-sm-6 col-md-4">
          <div class="form-group">
            <label for=""
              v-text=" (newAddress.country.code != 'CO') ?  $t('general.form.zipCode') : $t('general.form.nitCode') ">
            </label>
            <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.zipCode') ">
              <input type="text" v-model="newAddress.postal_code" placeholder="67614" class="form-control"
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>
        </div>

        <div class="col-6 col-sm-6 col-md-4">

          <div class="form-group">
            <label for="" v-text=" $t('general.form.phone') "> </label>
            <v-validation :rules="'min:10|' + (  !disabledFields.phone ? 'required' : '') " v-slot="{ errors }"
              :name=" $t('general.form.phone')">
              <the-mask :mask="['##-####-####']" placeholder="81-8000-8000" v-model="newAddress.phone "
                class="form-control" :class=" { 'is-invalid':errors.length > 0 } " :disabled="disabledFields.phone" />
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </v-validation>
          </div>
        </div>

      </div>
      <input type="submit" hidden>
    </v-observer>
  </div>
</template>

<script>
  import {
    EcStoreAddressModel,
  } from "@/plugins/beans.js"
  export default {
    props: {
      addressInformation: {
        type: Object,
        required: true,
      },
      disabledList: {
        type: Array,
      }
    },
    data() {
      return {
        loading: {
          countries: false,
          states: false,
        },
        countries: [],
        statlesList: [],
        provincesList: [],
        newAddress: EcStoreAddressModel(),
        disabledFields: {
          addressName: false,
          userName: false,
          userLastName: false,
          address1: false,
          address2: false,
          country: false,
          state: false,
          city: false,
          zip: false,
          phone: false,
        }
      }
    },
    watch: {
      addressInformation() {
        this.fnSetAddress();
      },
      newAddress: {
        handler: function (after, before) {
          this.$emit('set-address', this.ref, this.newAddress);
        },
        deep: true
      }
    },
    computed: {
      ref() {
        return 'addressForm' + this._uid;
      }
    },
    methods: {
      async fnValidateData() {
        if (await this.$refs[this.ref].validate()) {
          return this.newAddress
        } else {
          this.fnGlobalScrollToError(this.$refs[this.ref].refs, true);
          return null
        }
      },
      fnSetAddress() {
        let tempAddress = this.addressInformation;
        this.newAddress = {
          id: tempAddress.id,
          address_name: tempAddress.address_name,
          first_name: tempAddress.first_name,
          last_name: tempAddress.last_name,
          address1: tempAddress.address1,
          address2: tempAddress.address2,
          country: {
            code: tempAddress.country.code,
          },
          city: tempAddress.city,
          postal_code: tempAddress.postal_code,
          phone: tempAddress.phone,
          state: {
            code: null,
            name: null,
          },
        };

        if (tempAddress.state.code) {
          this.newAddress.state.code = tempAddress.state.code;
          this.fnGetProvinces(tempAddress.state.code);
          delete this.newAddress.state.name;
        } else {
          this.newAddress.state.name = tempAddress.state.name || null;
          delete this.newAddress.state.code;
        }

        if (this.newAddress.country.code) {
          this.fnGetStates(this.newAddress.country.code);
        }

        // console.log(this.$refs[this.ref]);
      },

      async fnGetStates(code, countryChanged) {
        this.loading.states = true;
        this.statlesList = [];
        this.statlesList = await this.fnApiGetStates(code);
        if (countryChanged) {
          this.newAddress.state.code = null;
          this.newAddress.state.name = null;
          if (this.statlesList.length > 0) {
            this.newAddress.state.code = this.statlesList[0].code_2_digits;
            this.fnGetProvinces(this.newAddress.state.code, true);
          }
        }
        this.loading.states = false;
      },

      async fnGetProvinces(code, stateChanged) {
        if (this.newAddress.country.code == 'CO' && this.newAddress.state.code) {
          this.provincesList = await this.fnApiGetProvinces(code);
          if (stateChanged) {
            this.newAddress.city = null;
            if (this.provincesList.length > 0) {
              this.newAddress.city = this.provincesList[0].code;
            }
          }
        } else {
          this.provincesList = [];
          if (stateChanged) {
            this.newAddress.city = null;
          }
        }
      },

      fnDisabledFields() {
        if (this.disabledList && this.disabledList.length > 0) {
          this.disabledList.map(field => {
            this.disabledFields[field] = true;
          });
        }
      }

    },
    async mounted() {
      this.fnSetAddress();
      this.fnDisabledFields();
      if (this.countries.length == 0) {
        this.loading.countries = true;
        this.countries = await this.fnApiGetCountries();
        this.loading.countries = false;
      }
      this.$emit('set-address', this.ref, this.newAddress);
    },
    beforeDestroy() {
      this.$emit('set-address', null, this.newAddress);
    },

  }
</script>

<style>

</style>